<template>
  <div>
    <Toast/>
    <!-- <keep-alive> -->
    <router-view ></router-view>
<!-- </keep-alive> -->
    
  </div>
  
</template>

<script>
import Toast from "./components/Toast/toast.vue";
import { setToken } from "./store/utils/auth.js";

export default {
  name: 'App',
  components: {
    Toast,
  },
  mounted() {
    // console.log(this.$store.state);
    setToken(this.$store.state.user_token);
  },
}
</script>

<style lang="scss">
  // Import Main styles for this application
@import 'assets/scss/style';
// CUSTOME CSS
.table-number {
  width: 60px;
}

.table-option {
  width: 110px;
}

.table-option-1 {
  width: 80px;
}

.table-option-2 {
  width: 100px;
}

.table-option-3 {
  width: 140px;
}

.table-option-4 {
  width: 160px;
}

.table-option-5 {
  width: 200px;
}

.custom-w-ttl{
    min-width:160px;
    // background-color:yellow;
}
.custom-w-nama{
    min-width:180px;
    // background-color:yellow;
}

.custom-w-umur{
    min-width:160px;
    // background-color:yellow;
}

.custom-w-alamat{
    min-width:240px;
    // background-color:yellow;
}

// .custom-title-card{
//   font-weight: 700;
//   font-size: 16px;
//   color: #d52f65;
// }

// .custom-title{
//   font-weight: 700;
//   font-size: 16px;
//   color: #d52f65;
// }

.custom-input-text{
  border-top-right-radius:0px;
  border-bottom-right-radius:0px
}

.custom-box-satuan{
  height: 32px;
  background-color: #D3D3D3;
  color:#202020;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0 10px;
}

.custom-box-satuan h6{
  margin:0;
  font-weight: normal;
  font-size: 12px;
}

.card {
  box-shadow: 0px 4px 4px 0px #00000026 !important;
}

// .btn-primary {
//   background-color: #9c4098;
// }

// .btn-primary:focus{
//   background-color: #9c4098;
// }

// .card .bg-dark{
//   background-image: linear-gradient(to right, #9C4098, #D52F65);
// }

// .modal-content .bg-primary{
//   background-image: linear-gradient(to right, #9C4098, #D52F65);
// }

// .modal-content .bg-success{
//   background-image: linear-gradient(to right, #9C4098, #D52F65);
// }

// .modal-content .bg-warning{
//   background-image: linear-gradient(to right, #9C4098, #D52F65);
// }

// .modal-content .bg-danger{
//   background-image: linear-gradient(to right, #9C4098, #D52F65);
// }

// .modal-content .bg-primary{
//   background-image: linear-gradient(to right, #9C4098, #D52F65);
// }
// END CUSTOME CSS

// CUSTOME CSS DATEPICKER
.mx-calendar-content{
  height:auto !important;
  padding-bottom:10px;
}
// END CUSTOME CSS DATEPICKER

// CUSTOME CSS SIDEBAR
.c-sidebar .c-sidebar-brand{
  // background-color:#fff;
  background-image: linear-gradient(#2ecc71, #27ae60);
}
.c-sidebar{
  background-image: linear-gradient(#2ecc71, #27ae60);
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle{
  color:#fff;
  font-weight:500
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon{
  color:#fff;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle{
  color:#fff;
  font-weight:500
}

.c-sidebar-nav-item a:hover{
  color:#202020 !important;
}

.c-sidebar-nav-dropdown a:hover{
  color:#202020 !important;
}
// END CUSTOME CSS SIDEBAR

// CUSTOM TABLE
.custom-table th{
  background-color: #2ecc71 ;
  color: #fff;
  font-weight: 600;
}

.custom-table th:first-child{
  border-top-left-radius: 8px;
}

.custom-table th:last-child{
  border-top-right-radius: 8px;
}

.custom-table th{
  border: 1px solid #F1F1F1 !important;
}

.custom-table td{
  border: 1px solid #F1F1F1 !important;
}
//END CUSTOM TABLE

// CUSTOM TITLE
.custom-title-color{
  color: #2ecc71;
}

.custom-title-color-white{
  color: #fff;
}

.custom-title-color-black{
  color: #000 !important;
}
// END CUSTOM TITLE

// CUSTOM DISPLAY ANTRIAN
.custom-bg-display{
  background: linear-gradient(109.67deg, #2ecc71 1.71%, #27ae60 100.75%);
}
// END CUSTOM DISPLAY ANTRIAN

#page-kunjungan .table-alergi th{
  background-color: #32d52f !important ;
  color: #fff;
  font-weight: 600;
}

#page-kunjungan .table-alergi th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-kunjungan .table-alergi th:nth-child(1) {
  border-top-left-radius: 8px;
}

#page-kunjungan .table-alergi th:nth-child(4) {
  border-top-right-radius: 8px;
}

#page-kunjungan .table-skrining th{
  background-color: #32d52f !important ;
  color: #fff;
  font-weight: 600;
}

#page-kunjungan .table-skrining th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-kunjungan .table-skrining th:nth-child(1) {
  border-top-left-radius: 8px;
}

#page-kunjungan .table-skrining th:nth-child(4) {
  border-top-right-radius: 8px;
}

#page-kunjungan .table-anatomi-tubuh th{
  background-color: #32d52f !important ;
  color: #fff;
  font-weight: 600;
}

#page-kunjungan .table-anatomi-tubuh  th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-kunjungan .table-anatomi-tubuh  th:nth-child(1) {
  border-top-left-radius: 8px;
}

#page-kunjungan .table-anatomi-tubuh th:nth-child(2) {
  border-top-right-radius: 8px;
}

#page-kunjungan .table-riwayat-lab th{
  background-color: #F1F1F1 !important ;
  color: #7C7C7C;
  font-weight: 600;
}

#page-kunjungan .table-riwayat-lab  th, td{
  border: 1px solid #F1F1F1 !important;
}

// #page-kunjungan .table-tindakan-kunjungan 
#page-kunjungan .table-tindakan-kunjungan th{
  background-color: #32d52f !important ;
  color: #fff;
  font-weight: 600;
}

#page-kunjungan .table-tindakan-kunjungan th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-kunjungan .table-tindakan-kunjungan th:nth-child(1) {
  border-top-left-radius: 8px;
}

#page-kunjungan .table-tindakan-kunjungan th:nth-child(15) {
  border-top-right-radius: 8px;
}

#page-input-pemeriksaan-lab .table-pemeriksaan-lab th{
  background-color: #32d52f !important ;
  color: #fff;
  font-weight: 600;
}

#page-input-pemeriksaan-lab .table-pemeriksaan-lab th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-input-pemeriksaan-lab .table-pemeriksaan-lab th:nth-child(1) {
  border-top-left-radius: 8px;
}

#page-input-pemeriksaan-lab .table-pemeriksaan-lab th:nth-child(7) {
  border-top-right-radius: 8px;
}

// 
#page-detail-pemeriksaan-lab .table-pemeriksaan-lab-detail th{
  background-color: #32d52f !important ;
  color: #fff;
  font-weight: 600;
}

#page-detail-pemeriksaan-lab .table-pemeriksaan-lab-detail th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-detail-pemeriksaan-lab .table-pemeriksaan-lab-detail th:nth-child(1) {
  border-top-left-radius: 8px;
}

#page-detail-pemeriksaan-lab .table-pemeriksaan-lab-detail th:nth-child(6) {
  border-top-right-radius: 8px;
}





#page-detail-pemeriksaan-lab .table-pengambilan-sampel-detail th{
  background-color: #32d52f !important ;
  color: #fff;
  font-weight: 600;
}

#page-detail-pemeriksaan-lab .table-pengambilan-sampel-detail th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-detail-pemeriksaan-lab .table-pengambilan-sampel-detail th:nth-child(1) {
  border-top-left-radius: 8px;
}

#page-detail-pemeriksaan-lab .table-pengambilan-sampel-detail th:nth-child(7) {
  border-top-right-radius: 8px;
}
// 
#page-input-pemeriksaan-lab .table-pengambilan-sampel th{
  background-color: #32d52f !important ;
  color: #fff;
  font-weight: 600;
}

#page-input-pemeriksaan-lab .table-pengambilan-sampel th, td{
  border: 1px solid #F1F1F1 !important;
}

#page-input-pemeriksaan-lab .table-pengambilan-sampel th:nth-child(1) {
  border-top-left-radius: 8px;
}

#page-input-pemeriksaan-lab .table-pengambilan-sampel th:nth-child(7) {
  border-top-right-radius: 8px;
}




.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color: #409c43 !important;
}

.nav-pills .nav-item a{
  color: #AFAFAF;
  font-weight: 600;
  font-size: 12px;
}

#page-kunjungan .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  background-color: #409c43 !important;
  color:#fff !important;
}

// #page-kunjungan  .nav-item a{
//   color: #AFAFAF !important;
//   font-weight: 600;
//   font-size: 12px;
// }

#page-nilai-rujukan-lab .multiselect__tags{
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

#page-pengamatan-kehamilan .card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 2;
  }
}

#page-antenatal .card-columns {
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 2;
  }
}


// #page-kunjungan #item-resep {
//   background-color: #D52F65 !important;
// } 

.table-radius-left {
  // border-top-left-radius: 50px !important;
  background-color: greenyellow !important;
  border-top-right-radius: 50px;
}
</style>
