import axios from "axios";
import store from "@/store/store.js";
// import ip from '../ipBackend.js'
const ip = localStorage.getItem('url')
// console.log('ip', ip)
// console.log('store.state.token', store.state.token)

const instance = axios.create({
  baseURL: ip,
});
instance.interceptors.request.use(async (req) => { 
  // console.log('store.state.token', store.state)
  let token = store.state.user_token;
  req.baseURL = localStorage.getItem('url')
  req.headers.Authorization = `Bearer ${token}`
  if (req.method == "post") {
    if (!req.data) req.data = {};
    //jika puskemas undefined / tidak terisi (bukan null)
    if(req.data.puskesmas_id === undefined){ 
      if (store.state.puskesmas_id) { //jika ada puskesmas id di store
        req.data.puskesmas_id = store.state.puskesmas_id
      }else if(localStorage.getItem('idp') && localStorage.getItem('idp') != 'null'){  //jika ada puskesmas id di local storage
        req.data.puskesmas_id = localStorage.getItem('idp')
      }
    }
  }
  return req;
});

export default instance;
