import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  sidebarWidth: "0",
  sidebar_items: [],
  login: null,
  puskesmas_id: null,
  puskesmas: {},
  asideShow: false,
  darkMode: false,
  alert: { variant: "", msg: "" },
  user_token: null,
  apm_id: null,
  rm_id: null,
  ptm_id: null,
  kia_id: null,
  antenatal_id: null,
  stock_opname_id: null,
  data_pasien: null,
  data_pasien_bpjs: null,
  user_role: null,
  role_id: null,
  organization_id: null,
  real_role_id: null,
  real_role: null,
  config_ip: null,
  asd: null,
  usia_kehamilan: null,
  tribulan: null,
  gpa: null,
  partograf_id: null,
  reload_data: null,
  data_user: {},
  gudang_id: "",
  barang_id: "",
  logo: "kudus.png",
  data_tindakan_lab: null,
  data_paket_lab: null,
  tanggal_sesi: null,
  tes: "",
  reset: false,
  color: {
    sidebar_background: "#688A91",
    sidebar_hover: "#6f9f9f",

    header_background: "#FFFFFF",
    header_font: "#E4E7EA",

    tab_background: "#979797",
    tab_font: "#FFFFFF",

    tab_active_background: "#52525B",
    tab_active_font: "#FFFFFF",

    root_background: "#FFFFFF",
    card_background: "#EBEDEF",
  },
  nama_user: "",
  all_akses: [],
  all_url: [],
  top_lists: [
    {
      name: "Tutup Semua",
      path: null,
      match: null,
      static: true,
    },
    {
      name: "Tutup Selain",
      path: null,
      match: null,
      static: true,
    },
  ],
};

const mutations = {
  clear_data(state) {
    state.user_token = null;
    state.apm_id = null;
    state.stock_opname_id = null;
    state.login = null;
    state.puskesmas_id = null;
    state.user_role = null;
    state.role_id = null;
    state.top_lists = [
      {
        name: "Tutup Semua",
        path: null,
        match: null,
        static: true,
      },
      {
        name: "Tutup Selain",
        path: null,
        match: null,
        static: true,
      },
    ]
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
  sidebar_width(value) {
    console.log("sampe");
    console.log(value);
  },
  set_pre_token(state, value) {
    // console.log("Pre tokeenenen");
    state.pre_user_token = value.token;
  },
  set_config_ip(state, ip) {
    state.config_ip = ip;
  },
  set_gudang_id(state, value) {
    state.gudang_id = value;
  },
  set_barang_id(state, value) {
    state.barang_id = value;
  },
  set_token(state, value) {
    // console.log(value,"tokeenenen");
    state.user_token = value;
  },
  set_puskesmas(state, value) {
    // console.log(value,"puskesmas");
    state.puskesmas_id = value;
  },
  set_puskesmas_full(state, value) {
    // console.log(value,"puskesmas");
    state.puskesmas = value;
  },
  set_login(state, value) {
    // console.log(value,"puskesmas");
    state.login = value;
  },
  set_role(state, value) {
    state.user_role = value;
  },
  set_role_id(state, value) {
    // console.log(value, 'iki store')
    state.role_id = value;
  },
  set_real_role_id(state, value) {
    state.real_role_id = value;
  },
  set_real_role(state, value) {
    state.real_role = value;
  },
  set_apm_id(state, value) {
    // console.log(value, 'itikiwirrrrrrrrr');
    state.apm_id = value;
  },
  set_kia(state, value) {
    // console.log(value, 'kia');
    state.kia_id = value;
  },
  set_organization_id(state, value) {
    // console.log(value, 'kia');
    state.organization_id = value;
  },
  set_antenatal(state, value) {
    // console.log(value, 'antenatal');
    state.antenatal_id = value;
  },
  set_usia_kehamilan(state, value) {
    console.log(value, "usia kehamilan");
    state.usia_kehamilan = value;
  },
  set_tribulan(state, value) {
    // console.log(value, 'tribulan');
    state.tribulan = value;
  },
  set_gpa(state, value) {
    state.gpa = value;
  },
  set_partograf(state, value) {
    state.partograf_id = value;
  },
  set_reload(state, value) {
    state.reload_data = value;
  },
  set_logo(state, value) {
    // console.log(value, 'logonya');
    state.logo = value;
  },
  set_rm_id(state, value) {
    // console.log(value, 'rm_id');
    state.rm_id = value;
  },
  set_ptm_id(state, value) {
    // console.log(value, 'ptm_id');
    state.ptm_id = value;
  },
  set_reset(state, value) {
    // console.log(value, 'ptm_id');
    state.reset = value;
  },
  set_data_tindakan_lab(state, value) {
    // console.log(value, 'ptm_id');
    state.data_tindakan_lab = value;
  },
  set_data_paket_lab(state, value) {
    // console.log(value, 'ptm_id');
    state.data_paket_lab = value;
  },
  set_tanggal_sesi(state, value) {
    // console.log(value, 'ptm_id');
    state.tanggal_sesi = value;
  },
  set_pasien(state, value) {
    // console.log(value, "data_pasien");
    state.data_pasien = value;
  },
  set_pasien_bpjs(state, value) {
    console.log(value, "data_pasien_bpjs");
    state.data_pasien_bpjs = value;
  },
  set_stockOpname_id(state, value) {
    // console.log(value, 'itikiwirrrrrrrrr');
    state.stock_opname_id = value;
  },
  set_alert(state, val) {
    state.alert = val;
    // console.log(val, 'ini val alert');
  },
  color(type, color_code) {
    state.color[type] = color_code;
  },
  setSidebarItems(state, items) {
    state.sidebar_items = items;
    console.debug("setSidebarItems: ", state.sidebar_items);
  },
  set_store(state, val) {
    state[val.name] = val.value;
  },
  set_data_user(state, data) {
    state.data_user = data;
  },
  set_nama_user_login(state, data) {
    state.nama_user = data;
  },
  set_all_akses(state, value) {
    // console.log("Pre tokeenenen");
    state.all_akses = value;
  },
  set_akses_url(state, value) {
    // console.log("Pre tokeenenen");
    state.all_url = value;
  },
  set_top_list(state, value) {
    // console.log(value.length, 'store')
    state.top_lists = value;
  },
};

const actions = {};

const res = new Vuex.Store({
  state,
  mutations,
  actions,
  plugins: [
    createPersistedState({
      key: "SIM_RS",
    }),
  ],
});

import json from "../../static.json";
for (const key in json) {
  if (Object.hasOwnProperty.call(json, key)) {
    const item = json[key];
    res.commit("set_store", {
      name: key,
      value: item,
    });
  }
}
export default res;
